import React from "react";
import styled from "styled-components";
import OBXPageContainer from "../components/OBXPageContainer";
import { useNavigate } from "react-router-dom";
import CircularProgressDetailedWidget from "../components/CircularProgressDetailedWidget";
import BreakdownBarChart from "../components/BreakdownBarChart";
const Progdata = [
    {
        name: 'Mechanical',
        ActualRemainingAmount : 220,
        ProjectedMaxValue : 250,
        CurrentAmount : 90,
        Unit: 'days',
    },
    {
        name: 'Electric',
        ActualRemainingAmount : 240,
        ProjectedMaxValue : 290,
        CurrentAmount : 90,
        Unit: 'days',
    },
    {
        name: 'Plumbing',
        ActualRemainingAmount : 220,
        ProjectedMaxValue : 250,
        CurrentAmount : 90,
        Unit: 'days',
    },
    {
        name: 'Architectural',
        ActualRemainingAmount : 50,
        ProjectedMaxValue : 220,
        CurrentAmount : 120,
        Unit: 'days',
    },
];

function ConstructionProgressPage() {
    const navigate = useNavigate();

    return (
        <OBXPageContainer headerTitle="Construction Progress">
            <WidgetContainer>
                <CircularProgressDetailedWidget
                    ProjectedMaxValue={390}
                    CurrentAmount={90}
                    ActualRemainingAmount={220}
                    Unit="days"
                    size={120}
                    strokeWidth={6}
                    Title="Overall Progress"
                />
            </WidgetContainer>
            <WidgetContainer>
                <BreakdownBarChart
                    data={Progdata}
                    title="Construction Breakdown"
                />
            </WidgetContainer>
        </OBXPageContainer>
    );
}
const WidgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
export default ConstructionProgressPage;
import React from 'react';
import styled from 'styled-components';
import CustomCircularProgress from './CustomCircularProgress';

function CircularProgressDetailedWidget({
                                            ActualRemainingAmount,
                                            ProjectedMaxValue,
                                            CurrentAmount,
                                            Unit = "days",
                                            Title = "Overall Progress",
                                            size = 120,
                                            strokeWidth = 18,

                                        }) {
    // Calculating progress in percentage using the ActualRemainingAmount and current amount
    const actualPercProgress = (CurrentAmount/ (CurrentAmount + ActualRemainingAmount)) * 100
    const ProjectedPerc = (CurrentAmount / ProjectedMaxValue) * 100;
    const PercDiff = actualPercProgress - ProjectedPerc;
    const DaysDiff = Math.abs(((CurrentAmount + ActualRemainingAmount) - ProjectedMaxValue));


    const scheduledInfo = PercDiff > 0
        ? `${DaysDiff} ${Unit} ahead`
        : `${DaysDiff} ${Unit} behind`;
    const dotColor = PercDiff > 0 ? "#2EC056" : "#F95C46";

    return (
        <WidgetContainer>
            <HeaderText>{Title}</HeaderText>
            {/* Circular Progress Indicator */}
            <ProgressBarContainer>
                <CustomCircularProgress
                    actualProgress={actualPercProgress.toFixed(0)}
                    projectedProgress={ProjectedPerc.toFixed(0)}
                    maxValue={100}
                    size={size}
                    strokeWidth={strokeWidth}
                />
            </ProgressBarContainer>

            {/* Detailed Progress Information */}
            <DetailSection>
                {/* Progress Block */}
                <ProgressBlock>
                    <Dot color="#00B2FF" />
                    <ProgressTitle>Progress</ProgressTitle>
                    <ProgressInfo>{`${actualPercProgress.toFixed(0)}%`}</ProgressInfo>
                    <ProgressInfo>{`${CurrentAmount.toFixed(0)} ${Unit}`}</ProgressInfo>
                </ProgressBlock>

                <DashedLine />

                {/* Scheduled Block */}
                <ProgressBlock>
                    <Dot color={dotColor} />
                    <ProgressTitle>Scheduled</ProgressTitle>
                    <ProgressInfo>{`${PercDiff.toFixed(0)}%`}</ProgressInfo>
                    <ProgressInfo>{scheduledInfo}</ProgressInfo>
                </ProgressBlock>

                <DashedLine />

                {/* ETC Block */}
                <ProgressBlock>
                    <Dot color="#4B4B4B" />
                    <ProgressTitle>ETC</ProgressTitle>
                    <ProgressInfo>{`${(100- actualPercProgress).toFixed(0)}%`}</ProgressInfo>
                    <ProgressInfo>{`${ActualRemainingAmount.toFixed(0)} ${Unit} to completion`}</ProgressInfo>
                </ProgressBlock>
            </DetailSection>
        </WidgetContainer>
    );
}

export default CircularProgressDetailedWidget;

const WidgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    width: 100%;
    max-width: 400px;
`;

const HeaderText = styled.h3`
    padding-left: 50px;
    text-align: left;
    margin-top: 8px;
    width: 100%;
    color: #fff;
    font: 500 19px/19px Inter, sans-serif;

`;

const ProgressBarContainer = styled.div`
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const DetailSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const ProgressBlock = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    word-break: break-word;
    margin: 0 10px;
    position: relative;
`;

const DashedLine = styled.div`
    width: 10px;
    height: 150px; 
    border-right: 2px dashed #fff;
    margin: 0 10px;
    align-self: stretch;
`;

const Dot = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin-bottom: 10px;
    margin-top: 15px;
`;

const ProgressTitle = styled.p`
    font-size: 14px;
    margin: 5px;
    color: #8a8e9e;
`;

const ProgressInfo = styled.p`
    font-size: 15px;
    color: #fff;
    margin: 5px 0;
`;
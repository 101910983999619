import React from "react";
import styled from "styled-components";

type ZoneTabProps = {
    zoneName: string;
    description: string;
    isActive: boolean;
    children?: any;
};

function ZoneTab({zoneName, description, isActive, children}: ZoneTabProps) {
    return (
        <TabContainer $isActive={isActive}>
            <ZoneInfo>
                <ZoneName>{zoneName}</ZoneName>
                <ZoneDescription>{description}</ZoneDescription>
            </ZoneInfo>
            {children}
        </TabContainer>
    );
}

const TabContainer = styled.div<{"$isActive": boolean}>`
    position: relative;
    border-radius: 18px;
    background-color: ${(props) => (props.$isActive ? "rgba(245, 246, 250, 0.08)" : "rgba(245, 246, 250, 0.04)")};
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 100px;
    align-items: center;
    justify-content: space-between;
    padding: 7px 14px;
    margin-top: 5px;
    cursor: pointer;
    box-sizing: border-box;
    font: 400 14px/20px Inter, sans-serif;
`;

const ZoneInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-right: 20px;
    min-width: 20%;
`;

const ZoneName = styled.h2`
    color: #fff;
    font-size: 14px;
    line-height: 1;
    margin: 0;
`;

const ZoneDescription = styled.p`
    color: #8a8e9e;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    padding-top: 2px;
`;




export default ZoneTab;

import React from "react";
import styled from "styled-components";

function MessageBubble({ name, isUserMessage }) {
  return (
    <BubbleContainer>
      <MainText>Hi {name},</MainText>
      {!isUserMessage && (
        <SubText>you can view the whole project from here.</SubText>
      )}
    </BubbleContainer>
  );
}

const BubbleContainer = styled.div`
  margin-top: 17px;
  color: #fff;
  margin-right: auto;
  margin-left: 5%;
  font: 400 24px/32px Questrial, sans-serif;
`;

const MainText = styled.div`
  text-align: left;
`

const SubText = styled.span`
  display: block;
  font-size: 16px;
  color: #8a8e9e;
`;

export default MessageBubble;
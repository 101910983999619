import React, { useState, useEffect } from "react";
import styled from "styled-components";

function OBXImage({ src, alt = "", style = {} }) {
    const [focusedImage, setFocusedImage] = useState(null);

    const handleImageClick = () => {
        setFocusedImage(src);
    };

    const handleClickOutside = (event) => {
        if (focusedImage && !event.target.closest('.focused-image')) {
            setFocusedImage(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [focusedImage]);

    return (
        <>
            <StyledImage
                src={src}
                alt={alt}
                onClick={handleImageClick}
                className={focusedImage === src ? 'focused-image' : ''}
                style={style}
            />
            {focusedImage && (
                <FullScreenImageContainer onClick={() => setFocusedImage(null)}>
                    <FullScreenImage src={focusedImage} alt="Focused" />
                </FullScreenImageContainer>
            )}
        </>
    );
}

const StyledImage = styled.img`
    max-width: ${({ size }) => size};
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
`;

const FullScreenImageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const FullScreenImage = styled.img`
    width: 100%;        
    height: 100%;       
    object-fit: contain;
    max-width: 90vw;   
    max-height: 90vh;  
`;

export default OBXImage;

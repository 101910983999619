import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const PromptBubbles = ({ prompts, onPromptClick, visible }) => {
    const [bubbleHeight, setBubbleHeight] = useState(0);
    const bubblesRef = useRef(null);

    useEffect(() => {
        if (bubblesRef.current && visible) {
            setBubbleHeight(bubblesRef.current.offsetHeight);
        } else {
            setBubbleHeight(0);
        }
    }, [visible, prompts]);

    return (
        <Container $bubbleHeight={bubbleHeight} $visible={visible}>
            <BubblesContainer ref={bubblesRef} $visible={visible}>
                {prompts.map((item, index) => (
                    <Bubble key={index} onClick={() => onPromptClick(item.prompt)}>
                        {item.label}
                    </Bubble>
                ))}
            </BubblesContainer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: ${props => props.$visible ? `${props.$bubbleHeight}px` : '0'};
    transition: height 0.3s ease;
    overflow: hidden;
    margin-bottom: 8px;
`;

const BubblesContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    opacity: ${props => props.$visible ? 1 : 0};
    transform: ${props => props.$visible ? "translateY(0)" : "translateY(-20px)"};
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: ${props => props.$visible ? "auto" : "none"};
`;

const Bubble = styled.button`
    background-color: rgba(245, 246, 250, 0.04);
    border: 1px solid rgba(245, 246, 250, 0.2);
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    color: #8a8e9e;
    font: 400 13px/1.5 Inter, sans-serif;
    &:hover {
        background-color: rgba(245, 246, 250, 0.1);
    }
    backdrop-filter: blur(5px);
`;

export default PromptBubbles;
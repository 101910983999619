import React from "react";
import styled from "styled-components";
import DateIcon from "../images/Date.png";

const DatePicker = ({ date, onDateChange }) => {
    const handleDateClick = () => {
        const dateInput = document.getElementById("date-input");
        if (dateInput) {
            dateInput.focus();
            dateInput.click();
            dateInput.showPicker();
        }
    };
    const today = new Date().toISOString().split("T")[0];

    return (
        <DateSelector>
            <CalendarIcon src={DateIcon} alt="Calendar" onClick={handleDateClick} />
            <SetDateButton onClick={handleDateClick}>Set Date</SetDateButton>
            <DateInput
                id="date-input"
                name="date"
                aria-label="Date"
                type="date"
                value={date}
                onChange={onDateChange}
                max={today}
            />
        </DateSelector>
    );
};

const DateSelector = styled.div`
    display: flex;
    margin-top: 1vw;
    gap: 1vw;
    align-items: center;
    cursor: pointer;
`;

const CalendarIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    width: 5vw;
    max-width: 30px;
    cursor: pointer;
`;

const DateInput = styled.input`
    background: transparent;
    border: none;
    color: #8a8e9e;
    cursor: pointer;
    font: 400 16px/20px Inter, sans-serif;
    &:focus {
        outline: none;
    }
    &::-webkit-calendar-picker-indicator {
        display: none; /* Hide the native calendar icon */
    }
`;

const SetDateButton = styled.button`
    background: transparent;
    border: none;
    color: #8a8e9e;
    cursor: pointer;
    font: 400 16px/20px Inter, sans-serif;
    &:focus {
        outline: none;
    }
`;

export default DatePicker;
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import addIcon from "../images/add.png";
import react from "react";
import ZoneTab from "./ZoneTab";
import PrevImage from "./OBXImage";
import PreviewContainer from "./PreviewContainer";

//TODO: Check if there are duplicate file names
//TODO: Only remove the file from the list instead of regenerating the preview list

type InputZoneProps = {
    zoneName: string;
    description: string;
    isActive: boolean;
    onChange: (zoneName: string, description: string, files: File[]) => void;
};

export default function InputZoneTab({zoneName, description, isActive, onChange}: InputZoneProps) {
    const uploadRef = useRef(null);

    const handleUpload = () => {
        uploadRef.current.click();
    };

    const [isAddHovered, setIsAddHovered] = useState(false);
    const [filelist, setFilelist] = useState<File[]>([]);

    const addFile = (e: { target: { files: Iterable<File> | ArrayLike<File>; }; }) => {
        const newFiles: File[] = Array.from(e.target.files);
        setFilelist((prevState: File[]) => [...prevState, ...newFiles]);
    };


    const removeFile = (fileName: string) => {
        setFilelist((prevState) => {
            const updatedFilelist = prevState.filter((file) => file.name !== fileName);
            onChange(zoneName, description, updatedFilelist);
            return updatedFilelist;
        });
    };


    useEffect(() => {
        onChange(zoneName, description, filelist);
    }, [filelist]);

    return (
        <ZoneTab zoneName={zoneName} description={description} isActive={isActive}>
            <PreviewContainer fileList={filelist} onRemove={removeFile}/>
            <AddContainer
                $isHovered={isAddHovered}
                onClick={handleUpload}
                onMouseEnter={() => setIsAddHovered(true)}
                onMouseLeave={() => setIsAddHovered(false)}
            >
                <AddIcon src={addIcon} alt={`${zoneName} icon`} />
                <input type="file" ref={uploadRef} onChange={addFile} hidden multiple />
            </AddContainer>
        </ZoneTab>
    );
}

const AddContainer = styled.div<{"$isHovered": boolean}>`
    aspect-ratio: 1;
    width: 38px;
    min-width: 38px;
    margin: 1% 0 1% 0;
    border-radius: 100%;
    border-width: 3px;
    border-color: rgba(235, 236, 240, 0.1);
    border-style: solid;
    text-align: center;
    object-position: center;
    background: ${(props) => (props.$isHovered ? "rgba(255, 255, 255, 0.1)" : "transparent")};
`;


const AddIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    margin-top: calc(50% - 9.5px);
    margin-left: 0px;
`;




import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import ChatInput from "../components/ChatInput";
import BorderButton from "../components/BorderButton";
import ArrowIcon from "../images/ArrowHead.svg";
import OptionsIcon from "../images/OptionDots.svg";
import ChatText from "../components/ChatText";
import {useLocation, useNavigate} from "react-router-dom";
import {ping, streamMessage, submitMessage} from "../proxy/ChatbotProxy";
import background from "../images/FullBackground.svg";
import PromptBubbles from "../components/PromptBubbles";


const LoremIpsum: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a justo sit amet libero aliquet mollis in nec enim. Curabitur vitae mi nec erat egestas faucibus. Sed consectetur lacinia euismod. Phasellus tincidunt sed neque ac dictum. Mauris nulla dui, convallis nec metus vel, feugiat tristique arcu. Mauris vitae justo sodales, vehicula elit ut, iaculis massa. Sed tincidunt eleifend sem et tristique. Vestibulum vehicula felis eget ipsum vehicula viverra. Sed nec orci interdum, molestie neque vel, semper magna. Vestibulum tincidunt eros sed posuere consequat.\n" +
    "\n" +
    "Quisque at leo at arcu elementum posuere nec a ex. Donec vel suscipit risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed porttitor lectus eget augue tincidunt, sit amet iaculis augue commodo. Vestibulum at dui vestibulum quam vestibulum bibendum. Mauris non ultricies justo, et ultrices ligula. Vivamus sed ultricies dolor, in egestas augue. Donec ac sagittis arcu, lobortis malesuada dui.\n" +
    "\n" +
    "Nullam vulputate orci vel ex pulvinar, eget suscipit sem luctus. Curabitur non orci ligula. Ut scelerisque suscipit nisl, a tempus ex fringilla eu. Nam euismod molestie odio, eget eleifend lectus fringilla sit amet. Nam mollis pellentesque tortor, eget aliquet diam aliquet non. Nunc id urna velit. Morbi imperdiet vehicula varius. Proin id ex vehicula, tincidunt felis sit amet, condimentum arcu. Cras gravida tempus dui, vitae pulvinar lacus imperdiet vel. Nunc accumsan, lorem at auctor consequat, ante augue bibendum magna, efficitur bibendum nisl sapien ut leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.\n" +
    "\n" +
    "Donec ut tortor vel augue dictum euismod vel sed ex. Donec imperdiet cursus varius. Proin cursus eleifend pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat leo sed metus eleifend feugiat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce suscipit laoreet nunc, sed porta lectus dignissim a. Nunc varius condimentum ex, et molestie nunc scelerisque vel. Duis a erat ut sapien imperdiet congue. Mauris vitae nibh a lorem volutpat eleifend.\n" +
    "\n" +
    "Nullam quis sem in diam commodo condimentum. Curabitur cursus fermentum fermentum. Nullam a iaculis massa, sed consectetur nulla. Fusce tincidunt massa at ornare suscipit. Proin nibh mauris, molestie quis orci sed, pellentesque commodo sapien. Nam vel dui et erat cursus dignissim maximus dignissim nisi. Praesent sit amet lorem commodo, aliquam tortor sit amet, tempus nisl. Aliquam felis purus, vestibulum eu quam id, mollis bibendum massa. Integer ullamcorper metus in tellus faucibus, vitae vestibulum ipsum volutpat. Vivamus nec vestibulum ex. Fusce aliquam nibh at congue sodales. Vestibulum ipsum velit, aliquet nec tortor in, elementum accumsan enim. Donec mollis fringilla tellus aliquam tincidunt.\n" +
    "\n" +
    "Nullam vel lacus felis. Sed quis nulla suscipit, commodo metus sit amet, aliquam quam. Cras suscipit, lectus eget congue mollis, nulla leo lobortis ligula, id sodales nunc metus quis mauris. Duis vulputate nulla est, vel auctor urna iaculis a. Nunc ultricies nulla eu felis congue luctus. Duis a pretium dui. Pellentesque pellentesque tincidunt felis. Ut aliquet leo in velit finibus viverra. Integer et lacinia magna, vitae sollicitudin augue. Pellentesque commodo rutrum velit, at vulputate diam ullamcorper id. Vestibulum et dui faucibus, aliquam enim suscipit, vehicula lorem. Quisque varius porttitor eros, et tincidunt turpis laoreet quis.\n" +
    "\n" +
    "Donec laoreet laoreet rutrum. Aenean rutrum ornare nisi nec lacinia. Vivamus vulputate diam a nunc aliquet semper. Sed convallis vel augue ac elementum. Nulla eget urna a nisi porttitor tincidunt. Etiam quis libero orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo rhoncus neque ut tempor. Duis porta ligula metus, a sagittis neque pulvinar a. Cras ullamcorper nunc eu finibus sagittis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas elementum fringilla volutpat. Phasellus vitae nisi lobortis, fringilla justo at, fermentum eros. Sed odio purus, maximus euismod viverra non, venenatis nec nulla. Vivamus tempus luctus vestibulum. Morbi nulla leo, tempus a finibus nec, vulputate vitae dolor.\n" +
    "\n" +
    "Sed porta, diam nec vulputate ornare, purus urna vestibulum lacus, ac facilisis ligula risus quis tortor. Praesent ac varius ex, in ornare tellus. Vivamus tempus vestibulum luctus. Cras gravida, erat id commodo ullamcorper, magna ipsum lobortis tortor, eget mollis lectus nisl sit amet erat. Praesent rhoncus dui quis leo maximus, ac volutpat tellus elementum. Morbi feugiat libero ac bibendum aliquam. Vivamus accumsan nibh et mauris venenatis vehicula. Curabitur id ex metus. Nulla et semper ex. Praesent vel mi et lectus sollicitudin tincidunt. Etiam tortor ipsum, hendrerit a arcu eget, facilisis vehicula ipsum. Phasellus iaculis quis leo eget dignissim. Nullam dignissim sollicitudin tempus. Sed vestibulum et nisi nec varius. Cras a sem ut erat elementum ultrices.\n" +
    "\n" +
    "Vestibulum placerat orci vitae magna mollis sollicitudin. Sed quis volutpat mauris. Aenean suscipit molestie ante, sed ultricies velit. Pellentesque elementum sagittis lorem a consectetur. Mauris quis pretium metus. Phasellus vulputate nibh ut nulla vestibulum, nec accumsan tellus elementum. Vestibulum vel hendrerit turpis, nec consectetur quam. Integer pharetra magna elementum, feugiat lorem eu, ornare justo. Quisque bibendum purus sed massa mollis, sed mattis quam elementum. Sed vestibulum bibendum lacus id sollicitudin. Sed quis pellentesque sem. Quisque sed dui sollicitudin, aliquet eros et, vulputate erat. Duis commodo, nisi sit amet viverra fringilla, ipsum lacus condimentum enim, at mollis tortor arcu at est. Nulla est eros, mollis at accumsan et, fringilla quis tortor.\n" +
    "\n" +
    "Cras eget fringilla enim. Praesent vel libero a diam pretium volutpat id sed enim. Proin sollicitudin nunc felis, sit amet eleifend orci luctus sit amet. Fusce ut dolor vel leo ultrices molestie. Aliquam at purus metus. Proin convallis pulvinar iaculis. Nam tempor vehicula efficitur. Proin scelerisque dolor at ligula ullamcorper, vitae rhoncus tortor bibendum. Curabitur aliquet nulla nibh. Vestibulum quis placerat dui, quis placerat massa."
const predefinedPrompts = [
    { label: "Construction Report", prompt: "Write me a technical report on the construction progress in paragraph form. You should not use point form and should not segment the report into too many sections. Make it segment by the big bands of work. Describe in each band the zone progress but do not make it point form or a list. It should be a paragraph. That flows naturally. Include an estimate of what I should have paid so far based on the completed work with accurate calculations. Include a summary of the work done and the work to be done. Include a summary of the work done and the work to be done. Include a safety report and a quality report. Include a summary of the work done and the work to be done. Include an estimate of the work progress and how much longer the project will take based on the gantt chart." },
    { label: "OverView", prompt: "Can you give me an overview of the project? Make it a paragraph that flows naturally with no bullet points or lists. Include only the project scope, and do not include a progress report." },
    { label: "CEO Office", prompt: "How is the electrical work in the CEO office? Answer in paragraph form" },
    { label: "Bathrooms", prompt: "What is the current state of the bathrooms? Answer in paragraph form" },
    { label: "Plenums", prompt: "What is the current state of the plenums? Answer in paragraph form" },

];
type MessageProps = {
    text?: string;
    images?: string[];
    isAgent: boolean;
    generator?: (a: (b: any) => void) => void;
    onStreamEnd?: (a: string) => void;
};
function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


// TODO: Implement easing for text response
function ChatPage(){
    // Router hooks
    const location = useLocation();
    const navigate = useNavigate();

    // State setup

    let firstPrompt : string = location.state.prompt;
    const initMessages : MessageProps[] = [];
    if (firstPrompt) {
        initMessages.push({text: firstPrompt, isAgent: false});
        // Handle LLM Call here, for now:
    }

    const chatBottomRef = useRef(null);
    const scrollToBottom = () => {
        chatBottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    async function DummyGenerator(handler: (a: any) => void) {
        for (let word of LoremIpsum.split(" "))
        {
            await sleep(10);
            scrollToBottom();
            handler({messages: word + " "});
            console.log("scrolled");
        }
    }

    const [messages, setMessages] = useState<MessageProps[]>(initMessages);
    const [sessionId, setSessionId] = useState<string>("");
    const [bubblesVisible, setBubblesVisible] = useState<boolean>(false);

    // State update functions
    const AddMessage = (messageObj: MessageProps) => {
        setMessages(prevMessages => [...prevMessages, messageObj]);
    };

    const SubmitMessage = async (message: string) => {
        const idx = messages.length - 1;
        AddMessage({
            isAgent: true,
            generator: (handler) => streamMessage(message, handler, sessionId),
        });
    };



    const GetInput = (text: string) => {
        AddMessage({text: text, isAgent: false});
        SubmitMessage(text).then();
    };

    const handlePromptClick = (prompt: string) => {
        GetInput(prompt);
    };

    const toggleBubblesVisibility = () => {
        setBubblesVisible(!bubblesVisible);
    };

    // First prompt handling
    useEffect(() => {
        if (firstPrompt) {
            SubmitMessage(firstPrompt).then();
        }
    }, []);



    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    return (
        <ChatPageContainer>
            <PageHeader>
                <BorderButton
                    src={ArrowIcon}
                    alt={"Back"}
                    size={"27px"}
                    onSubmit={() => navigate('/')}
                />
                <HeaderTitle>AiDA</HeaderTitle>
                <BorderButton src={OptionsIcon} alt={"Options"} size={"27px"} />
            </PageHeader>
            <ChatSection >
                {Array.from(messages).map((item, i) => {
                    if (item.text)
                        return (<ChatText text={item.text} images={item.images} leftAligned={item.isAgent} key={i}/>)
                    else if (item.generator)
                        return (<ChatText generator={item.generator} leftAligned={item.isAgent} key={i}/>);
                })}
                <div ref={chatBottomRef} />
            </ChatSection>
            <PageFooter>
                <PromptBubbles prompts={predefinedPrompts} onPromptClick={handlePromptClick} visible={bubblesVisible} />
                <ChatInput onSubmit={GetInput} onAiIconClick={toggleBubblesVisibility} />
            </PageFooter>
        </ChatPageContainer>
    );
}

const ChatPageContainer = styled.main`
    display: flex;
    flex-direction: column;
    background-image: url(${background});
    height: 100dvh;
`;

const PageHeader = styled.section`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5% 5%;
`;

const HeaderTitle = styled.span`
    color: #fff;
    text-align: center;
    font: 550 22px/20px Inter, sans-serif;
    padding-top: 4%;
`;

const ChatSection = styled.section`
    display: block;
    height: 100%;

    overflow-y: scroll;
    margin: 0 2% 0 2%;
    border-radius: 19px;
`;

const PageFooter = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.3% 2% 3% 2%;
    box-sizing: border-box;
`;

export default ChatPage;
import React from "react";
import styled from "styled-components";
import CircularProgressWidget, { ValueFunctions } from "../components/CircularProgressWidget";
import OBXPageContainer from "../components/OBXPageContainer";
import { useNavigate } from "react-router-dom";


function OverviewPage() {
    const navigate = useNavigate();

    return (
        <OBXPageContainer headerTitle="Overview">
            <WidgetsGrid>
                <CircularProgressWidget
                    title={"Construction Progress"}
                    subText={"On Schedule"}
                    progress={80}
                    maxProgress={100}
                    minWidth="100px"
                />
                <CircularProgressWidget
                    title={"Safety Score"}
                    subText={"Good"}
                    progress={85}
                    maxProgress={100}
                    minWidth="100px"
                />
                <CircularProgressWidget
                    title={"Permits Approved"}
                    subText={"On Schedule"}
                    progress={0}
                    maxProgress={0}
                    valueFormat={ValueFunctions.fraction}
                    minWidth="100px"
                />
                <CircularProgressWidget
                    title={"Construction Cost"}
                    subText={"Within Budget"}
                    progress={0}
                    maxProgress={0}
                    valueFormat={ValueFunctions.dollars}
                    minWidth="100px"
                />
                <CircularProgressWidget
                    title={"Procurement"}
                    subText={"On Schedule"}
                    progress={0}
                    maxProgress={0}
                    minWidth="100px"
                />
                <CircularProgressWidget
                    title={"Sales"}
                    subText={"On Schedule"}
                    progress={0}
                    maxProgress={0}
                    valueFormat={ValueFunctions.dollars}
                    minWidth="100px"
                />
            </WidgetsGrid>
        </OBXPageContainer>
    );
}


const WidgetsGrid = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;

    @media (min-width: 600px) {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    @media (max-width: 599px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export default OverviewPage;
import React from "react";
import styled from "styled-components";
import UserAvatar from "./UserAvatar";
import MessageBubble from "./WelcomeMessage";
import ChatInput from "./ChatInput";
import SquaredButton from "./SquaredButton";
import settingLogo from "../images/setting-4.svg"
import notificationLogo from "../images/notification.svg"
import {useNavigate} from "react-router-dom";
function WelcomeSection() {
    const navigate = useNavigate()
    return (
        <WelcomeContainer>
            <IconBar>
                <UserAvatar
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/97c30d2e07aa3a499f4d4432965bbb62b33e3d31717e440d4c6b747162b8f26d?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77"
                    alt="User avatar"
                />
                <IconButtons>
                    <SquaredButton
                        src={notificationLogo}
                        alt="Notification"
                        size="small"
                    />
                    <SquaredButton
                        src={settingLogo}
                        alt="Settings"
                        size="small"
                    />
                </IconButtons>

            </IconBar>
            <WelcomeMessage>
                <MessageBubble
                    name="Raphael"
                    isUserMessage={false}
                />
            </WelcomeMessage>
            <InputSection>
                <ChatInput onSubmit={(text)=> {
                    navigate('/chat', {
                        state: {
                            prompt: text,
                        }
                    })
                }}/>

            </InputSection>
        </WelcomeContainer>
  );
}

const WelcomeContainer = styled.main`
  display: flex;
  //max-width: 335px;
  flex-direction: column;
  @media (max-width: 640px) {
    margin: 0 auto;
  }
`;

const IconBar = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 5px;
    padding: 5% 4% 2% 4%;
    justify-content: space-between;
`;

const IconButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
`

const WelcomeMessage = styled.section`
    display: flex;
    align-items: flex-start;
    gap: 0px;
`

const InputSection = styled.section`
  display: flex;
  margin-top: 11px;
  gap: 5px;
  padding: 1% 4%;
`;

export default WelcomeSection;
export const zoneData = [
    {
        zoneName: "Zone 1",
        description: "Entrance",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: true
    },
    {
        zoneName: "Zone 2",
        description: "CEO Office",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 3",
        description: "CEO Bathroom",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 4",
        description: "Entrance Bathroom",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 5",
        description: "Corridor",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 6",
        description: "Meeting Room 1",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 7",
        description: "Meeting Room 2",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 8",
        description: "IT Room",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 9",
        description: "Bathroom 1",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 10",
        description: "Kitchen",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 11",
        description: "Cubicle Area",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive
        : false
    },
    {
        zoneName: "Zone 12",
        description: "Office 1",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 13",
        description: "Office 2",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 14",
        description: "Office 3",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 15",
        description: "Bathroom 2",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    }

];
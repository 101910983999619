import React from "react";
import styled from "styled-components";
import ContentSection from "./ContentSection";
import ReportTile from "../images/ReportTile";
import ConstructionIcon from "../images/ConstructionIcon.svg"
import SalesIcon from "../images/SalesIcon.svg"
import BudgetIcon from "../images/BudgetIcon.svg"
import SafetyIcon from "../images/SafetyIcon.svg"
function ReportSection() {
    return (
      <ContentSection title={"Daily Reports"}>
          <TileContainer>
              <ReportTile title={"Construction Report"}
                         subText={"On Schedule"}
                         icon={ConstructionIcon}/>
              <ReportTile title={"Sales Report"}
                          subText={"Meeting Projections"}
                          icon={SalesIcon}/>
              <ReportTile title={"Budget Report"}
                          subText={"Meeting Projections"}
                          icon={BudgetIcon}/>
              <ReportTile title={"Safety Report"}
                          subText={"Meeting Projections"}
                          icon={SafetyIcon}/>
          </TileContainer>
      </ContentSection>
    );
}

const TileContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0% 4% 0% 0%;
`

export default ReportSection;
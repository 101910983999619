import React from "react";
import styled from "styled-components";
import { default as CircularProgressWidget, ValueFunctions} from "./CircularProgressWidget";
import 'react-circular-progressbar/dist/styles.css';
import ContentSection from "./ContentSection";



function OverviewSection({ navigateTo }) {
    return (
        <ContentSection title="Overview" navigateTo={navigateTo}>

        <WidgetContainer>
                <CircularProgressWidget title={"Construction Progress"}
                                        subText={"On Schedule"}
                                        progress={80}
                                        maxProgress={100}
                />
                <CircularProgressWidget title={"Safety Score"}
                                        subText={"Good"}
                                        progress={85}
                                        maxProgress={100}
                />
                <CircularProgressWidget title={"Permits Approved"}
                                        subText={"On Schedule"}
                                        progress={0}
                                        maxProgress={0}
                                        valueFormat={ValueFunctions.fraction}
                />
                <CircularProgressWidget title={"Construction Cost"}
                                        subText={"Within Budget"}
                                        progress={0}
                                        maxProgress={0}
                                        valueFormat={ValueFunctions.dollars}
                />
                <CircularProgressWidget title={"Procurement"}
                                        subText={"On Schedule"}
                                        progress={0}
                                        maxProgress={0}
                />
                <CircularProgressWidget title={"Sales"}
                                        subText={"On Schedule"}
                                        progress={0}
                                        maxProgress={0}
                                        valueFormat={ValueFunctions.dollars}
                />
            </WidgetContainer>
        </ContentSection>
    );
}

const WidgetContainer = styled.section`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
`
export default OverviewSection;
import React, { useEffect, useId, useRef, useState } from "react";
import styled from "styled-components";
import sendIcon from "../images/send.svg";
import SquaredButton from "./SquaredButton";

function ChatInput({ onSubmit = (text) => {}, onAiIconClick = () => {} }) {
    const id = useId();
    const [input, setInput] = useState('');
    const inputRef = useRef(null);

    const submitFunction = () => {
        console.log("Submitted")
        onSubmit(input);
        inputRef.current.value = ''
        setInput('');
        inputRef.current.style.height = 'auto';
    };

    const adjustHeight = () => {
        console.log(inputRef.current.scrollHeight)
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        console.log(`The scroll height is: ${inputRef.current.scrollHeight}px`)
    };
    useEffect(() => {
        adjustHeight(); // Set initial height on component mount
    }, []);

    console.log(input)
    return (
        <InputContainer>
            <InputForm onSubmit={e => {
                e.preventDefault();
                submitFunction();
            }}>
                <InputWrapper>
                    <AiIcon
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc9ff3a9e0412c2739993be28f7c966003b54153254f6a965a07fd699179101d?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77"
                        alt="AI Assistant Icon"
                        onClick={onAiIconClick}
                    />
                    <label htmlFor={id}></label>
                    <TextArea
                        ref={inputRef}
                        id={id}
                        placeholder="Ask AiDA"
                        value={input}
                        onChange={e => {
                            setInput(e.target.value);
                            adjustHeight();
                        }}
                        rows={1}
                    />
                </InputWrapper>
                <MicIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/eec35f2d715de66427c8b32a3c576d4d0e7b8fea0cad017b979455629766eec8?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77" alt="Send message" />
            </InputForm>
            <SquaredButton
                src={sendIcon}
                alt="Send Button"
                color="#1640D6"
                opacity="1"
                size={"51px"}
                onSubmit={submitFunction}
            />
        </InputContainer>
    );
}
const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
`
const InputForm = styled.form`
    border-radius: 16px;
    background-color: rgba(245, 246, 250, 0.04);
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-end;
    height: auto;
    flex-shrink: 1;
    padding: 13px 22px;
    &:focus {
        outline: none;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: flex-end;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const AiIcon = styled.img`
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: bottom;
    width: 24px;
    cursor: pointer;
`;

const TextArea = styled.textarea`
    background: transparent;
    object-position: center;
    width: 100%;
    border: none;
    color: #8a8e9e;
    font: 400 16px/1.5 Inter, sans-serif;
    resize: none;
    overflow: hidden;
    max-height: 150px;
    overflow-y: auto;
    flex-shrink: 1;
    height: auto;
    padding: 0;
    &::placeholder {
        color: #8a8e9e;
    }
    &:focus {
        outline: none;
    }
`;

const MicIcon = styled.img`
    aspect-ratio: 0.74;
    object-fit: contain;
    object-position: bottom;
    width: 14px;
    cursor: pointer;
`;

export default ChatInput;
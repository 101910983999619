import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";

function SquaredButton({
                           src,
                           alt,
                           size = "small",
                           radius = "16px",
                           color = "rgba(235, 236, 240, 1)",
                           opacity = "0.1",
                           onSubmit = () => {}
                       }) {
    const [isActive, setIsActive] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    const varColor = tinycolor(color);
    varColor.setAlpha(!isActive ? opacity : opacity * 5);

    const handleTouchStart = () => {
        setIsActive(true);
    };

    const handleTouchEnd = () => {
        setIsActive(false);
        onSubmit();
    };

    const handleMouseEnter = () => {
        if (!isTouchDevice) setIsActive(true);
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) setIsActive(false);
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (!isTouchDevice) onSubmit();
    };

    return (
        <Button
            type="submit"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onClick={handleClick}
            $color={varColor.toRgbString()}
            $size={ParseSize(size)}
            $radius={radius}
            $isTouchDevice={isTouchDevice}
        >
            <ButtonImage src={src} alt={alt} />
        </Button>
    );
}

function ParseSize(size) {
    if (size === "small" || size === "large") {
        return size === "small" ? "44px" : "50px";
    }
    return size;
}

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    margin-left: 7px;
    aspect-ratio: 1;
    background-color: ${props => props.$color};
    border-radius: ${props => props.$radius};
    width: ${props => props.$size};
    height: ${props => props.$size};
    transition: background-color 0.3s, opacity 0.3s;

    ${props => !props.$isTouchDevice && `
    &:hover {
      background-color: ${tinycolor(props.$color).darken(10).toString()};
      opacity: 0.8;
    }
  `}

    &:active {
        background-color: ${props => tinycolor(props.$color).darken(20).toString()};
        opacity: 0.9;
    }
`;

const ButtonImage = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
`;

export default SquaredButton;
import React, { ReactNode } from "react";
import styled from "styled-components";
import BorderButton from "../components/BorderButton";
import ArrowIcon from "../images/ArrowHead.svg";
import OptionsIcon from "../images/OptionDots.svg";
import { useNavigate } from "react-router-dom";
import background from "../images/FullBackground.svg";
import ChatInput from "../components/ChatInput";

interface OBXPageContainerProps {
    children?: ReactNode;
    headerTitle?: string;
}

function OBXPageContainer({ children, headerTitle = "OBX" }: OBXPageContainerProps) {
    const navigate = useNavigate();

    return (
        <PageContainer className="obx-page">
            <PageHeader>
                <BorderButton
                    src={ArrowIcon}
                    alt={"Back"}
                    size={"27px"}
                    onSubmit={() => navigate('/')}
                />
                <HeaderTitle>{headerTitle}</HeaderTitle>
                <BorderButton src={OptionsIcon} alt={"Options"} size={"27px"} />
            </PageHeader>
            <InputSection>
                <ChatInput onSubmit={(text)=> {
                    navigate('/chat', {
                        state: {
                            prompt: text,
                        }
                    })
                }}/>
            </InputSection>
            <ChildrenSection>
                {children}
            </ChildrenSection>
        </PageContainer>
    );
}

const PageContainer = styled.div`
    flex-direction: column;
    background-image: url(${background});
    //width: 100vw;
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: auto;
`;

const PageHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5% 5% 0 5%;
`;

const HeaderTitle = styled.span`
    color: #fff;
    text-align: center;
    font: 520 20px/20px Inter, sans-serif;
    padding-top: 4%;
`;

const InputSection = styled.section`
    display: flex;
    margin-top: 11px;
    gap: 5px;
    padding: 13px 14px;
`;

const ChildrenSection = styled.section`
    padding-top: 1px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 25px;
    
`;

export default OBXPageContainer;
import React from "react";
import styled from "styled-components";
import SquaredButton from "../components/SquaredButton";
import Arrow from "../images/arrow-right.svg"
function ReportTile({title,
                    subText,
                    icon}) {
    return (
        <TileContainer>
            <SquaredButton src={icon} alt={"Tile Icon"} size={"56px"} />
            <TileBody>
                <TextContainer>
                    {title}
                    <SubText>
                        {subText}
                    </SubText>
                </TextContainer>
                <SquaredButton src={Arrow} alt={"Arrow"} size={"40px"}/>
            </TileBody>
        </TileContainer>
    );
}

const TileContainer = styled.main`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
`;

const TileBody = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 3%;
    justify-content: space-between;
    width: 100%;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    font: 400 18px/28px Inter, sans-serif;
    color: #fff;
    text-align: left;
`;

const SubText = styled.span`
    font: 400 12px/20px Inter, sans-serif;
    color: #8a8e9e;
    text-align: left;
`;

export default ReportTile;
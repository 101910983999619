import styled from "styled-components";
import Popup from "reactjs-popup";
import background from "../images/FullBackground.svg";
import SiteVisitForm from "../components/SiteVisitForm";
import React from "react";
import Toolbar, {ToolbarButton} from "../components/Toolbar";
import ImagesPage from "./ImagesPage";

type PageMode = "upload" | "view";

export default function UploadPage({open, onChange}) {
    const [mode, setMode] = React.useState<PageMode>("upload");
    const Buttons: ToolbarButton[] = [
        {text: "Upload", onClick: () => setMode("upload")},
        {text: "View", onClick: () => setMode("view")},
    ]
    return (
        <StyledPopup open={open} onClose={onChange} modal>
            <StyledToolbar buttons={Buttons} />
            {mode === "upload" ? <SiteVisitForm/> : <ImagesPage/>}
        </StyledPopup>
    )
}

const StyledPopup = styled(Popup)`
    &-content {
        background: url("${background}");
        border-radius: 50px;
        width: 86%;
        height: 75%;
        max-width: 1200px;
        max-height: 800px;
    }

    &-overlay {
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledToolbar = styled(Toolbar)`
    margin: 10px 12px 5px 12px;
    justify-content: space-evenly;
    color: white;
`;
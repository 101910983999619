import React, {useEffect, useState} from "react";
import {list_images, remove_image, ListImagesResult} from "../proxy/ChatbotProxy";
import styled from "styled-components";
import ZoneTab from "../components/ZoneTab";
import PreviewContainer from "../components/PreviewContainer";
import {PreviewProps} from "../components/PreviewContainer";
export default function ImagesPage() {
    const [zoneData, setZoneData] = useState<ListImagesResult[]>([]);
    useEffect(() => {
        list_images().then((images: ListImagesResult[]) => {
            setZoneData(images.sort((a, b) => {
                const aName = a.zoneName.replace("Zone", "");
                const bName = b.zoneName.replace("Zone", "");
                return parseInt(aName) - parseInt(bName);
            }));
        });
    }, []);

    const onRemove = (fileName: string) => {
        const imageIdx = zoneData.findIndex((zone) => zone.images && zone.images.find((image) => image.id === fileName));
        const image = zoneData[imageIdx].images.find((image) => image.id === fileName);
        remove_image(image.id).then(() => {
            console.log("Image deleted");
        });
        setZoneData((prevState) => {
            prevState.forEach((zone) => {
                const idx = zone.images.findIndex((image) => image.id === fileName);
                if (idx > -1) {
                   zone.images.splice(idx, 1);
               }
            });
            return prevState;
        });

    }
    const getPreviewProps: (a: ListImagesResult) => PreviewProps[] = (image) => {
        return image.images.map((image, index) => {
            return {filename: image.id, previewUrl: image.image_url};
        });
    }
    return (
        <PageContainer>
            <ZoneList>{
                zoneData.map((image, index) => (
                    <ZoneTab key={index} {...image} isActive={true}>
                        <PreviewContainer fileList={getPreviewProps(image)} onRemove={onRemove}/>
                    </ZoneTab>
                ))
            }</ZoneList>
        </PageContainer>
    );
}

const PageContainer = styled.div`
    border-radius: 30px;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    font-weight: 400;
    display: block;
    //flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    box-sizing: border-box;
`;
const ZoneList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;
import React, { useState } from "react";
import styled from "styled-components";
import ZoneTab from "./InputZoneTab";
import { zoneData } from "./zoneData";
import { uploadZoneForm, ZoneFormProps } from "../proxy/ChatbotProxy";
import DatePicker from "./DatePicker";

function SiteVisitForm() {
    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const [formData, setFormData] = useState<ZoneFormProps>({
        comment: "",
        date: getCurrentDate(),
        zones: []
    });

    const [uploaded, setUploaded] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const uploadFileHandler = (zone_id: string, description: string, files: File[]) => {
        setFormData((prevState) => {
            if (!prevState.zones.find((zone) => zone.id === zone_id)) {
                return {
                    ...prevState,
                    zones: [...prevState.zones, { id: zone_id, description: description, files: files }]
                };
            }
            return {
                ...prevState,
                zones: prevState.zones.map((zone) => {
                    if (zone.id === zone_id) {
                        return { ...zone, description: description, files: files };
                    }
                    return zone;
                })
            };
        });
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        uploadZoneForm(formData).then((response) => { setUploaded(response === 200 ? "success" : "failed"); });
    };

    return (
        <FormContainer>
            <FormContent onSubmit={e => e.preventDefault()}>
                <FormContentContainer>
                    <Header>
                        <Title>Add Site Visit</Title>
                        <CommentInput name="comment" placeholder="Tap to add comments..." onChange={handleInputChange} />
                        <DatePicker date={formData.date} onDateChange={handleInputChange} />
                    </Header>
                    <Divider />
                    <Main>
                        <ZoneList>
                            {zoneData.map((zone, index) => (
                                <ZoneTab key={index} {...zone} onChange={uploadFileHandler} />
                            ))}
                        </ZoneList>
                    </Main>
                    <Footer>
                        {uploaded !== "" ?
                            (uploaded === "success" ? <SuccessMessage>Upload successful</SuccessMessage>
                                : <ErrorMessage>Upload failed</ErrorMessage>) : null}
                        <ConfirmButton onSubmit={handleSubmit} onClick={handleSubmit}>Confirm</ConfirmButton>
                    </Footer>
                </FormContentContainer>
            </FormContent>
        </FormContainer>
    );
}

const FormContainer = styled.div`
    border-radius: 30px;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormContent = styled.form`
    border-radius: 30px;
    background-color: #15151c;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 2px solid #282831;
    box-sizing: border-box;
    overflow: hidden;
`;

const FormContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.div`
    padding: 20px;
    padding-bottom: 0;
    color: #8a8e9e;
`;

const Title = styled.h1`
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
`;

const CommentInput = styled.textarea`
    background: transparent;
    border: none;
    color: #8a8e9e;
    resize: none;
    margin-bottom: 5px;
    padding: 0;
    font: 400 16px/20px Inter, sans-serif;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const Divider = styled.hr`
    min-height: 1px;
    width: 100%;
    border: none;
    border-top: 1px dashed rgba(216, 218, 229, 0.16);
`;

const Main = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 0 10px;
`;

const ZoneList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

const Footer = styled.div`
    padding: 10px;
`;

const ConfirmButton = styled.button`
    width: 100%;
    border-radius: 15px;
    background-color: #1640d6;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    border: none;
    cursor: pointer;
`;

const SuccessMessage = styled.span`
    color: #0f510f;
`

const ErrorMessage = styled.span`
    color: #710606;
`

export default SiteVisitForm;

import PrevImage from "./OBXImage";
import React, {useEffect, useState} from "react";
import styled from "styled-components";

export type PreviewProps = {
    filename: string;
    previewUrl: string;
}

type PreviewContainerProps = {
    fileList: File[] | PreviewProps[];
    onRemove: (a: string) => void;
}



export default function PreviewContainer({fileList, onRemove}: PreviewContainerProps) {
    const [previewList, setPreviewList] = useState<PreviewProps[]>([]);
    useEffect(() => {
        if (fileList.length > 0) {
            const newPreviews = [];
            fileList.forEach((file: File | PreviewProps) => {
                if (file instanceof File) {
                    newPreviews.push({filename: file.name, previewUrl: URL.createObjectURL(file)});
                } else {
                    newPreviews.push(file);
                }
            });
            setPreviewList((prevState: PreviewProps[]) => [...newPreviews]);
        }
    }, [fileList])



    const removeFile = (fileName: string) => () => {
        console.log("Removing file: ", fileName);
        setPreviewList((prevState: PreviewProps[]) => {
            const updatedPreviewList = prevState.filter((file) => file.filename !== fileName);
            const url = prevState.find((file) => file.filename === fileName)?.previewUrl;
            if (url) {
                URL.revokeObjectURL(url);
            }
            onRemove(fileName);
            return updatedPreviewList;
        });
    };

    return (
        <PreviewDiv>
            {previewList.map((entry: PreviewProps, index: number) => (
                <PreviewWrapper key={index}>
                    <PrevImage src={entry.previewUrl}
                               alt={entry.filename}
                               style={{ maxHeight: "60px", margin: "5% 0 2% 0", height: "100%", border: "1px solid #ddd" }}
                    />
                    <CloseButton onClick={removeFile(entry.filename)}
                                 onSubmit={removeFile(entry.filename)}/>
                </PreviewWrapper>
            ))}
        </PreviewDiv>
    )


}

const PreviewDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
    margin-right: 8px;
    position: relative;
`;

const PreviewWrapper = styled.div`
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    border-radius: 100%;
    top: 0;
    right: -5px;
    background-color: rgba(50, 51, 57, 0.9);
    border: none;
    aspect-ratio: 1;
    width: 14px;
    z-index: 1 !important;
`;
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";

function WideButton({  text,
                       className = "button",
                       size = "90px",
                       radius = "16px",
                       color = "#355BE1",
                        opacity = "1",
                        onClick }) {
    const [isActive, setIsActive] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    const varColor = tinycolor(color);
    varColor.setAlpha(!isActive ? opacity : opacity * 5);

    const handleTouchStart = () => {
        setIsActive(true);
    };

    const handleTouchEnd = () => {
        setIsActive(false);
        onClick();
    };

    const handleMouseEnter = () => {
        if (!isTouchDevice) setIsActive(true);
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) setIsActive(false);
    };

    const handleClick = (e) => {
        e.preventDefault();
        console.log("Button clicked");
        if (!isTouchDevice) onClick();
    };

    return (
        <div className={className}>
            <Button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={handleClick}
                $isTouchDevice={isTouchDevice}
                $color={color}
                $size={size}
                $radius={radius}>

            <ButtonText>{text}</ButtonText>
        </Button></div>
    );
}
const Button = styled.button`
    border: none;
    padding: 1px;
    margin-left: 7px;
    background-color: ${props => props.$color};
    border-radius: ${props => props.$radius};
    width: ${props => props.$size};
    height: auto;
    min-height: 34px;
    transition: background-color 0.3s, opacity 0.3s;


    ${props => !props.$isTouchDevice && `
    &:hover {
      background-color: ${tinycolor(props.$color).darken(10).toString()};
      opacity: 0.8;
    }
  `}

    &:active {
        background-color: ${props => tinycolor(props.$color).darken(20).toString()};
        opacity: 0.9;
    }
`;

const ButtonText = styled.span`
  object-fit: contain;
  justify-content: center;
    color: #fff;
    font: 400 12px/30px Inter, sans-serif;
`;



export default WideButton;
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import OverviewSection from "../components/OverviewSection";
import ReportSection from "../components/ReportSection";
import UploadPage from "./UploadPage";
import WelcomeSection from "../components/WelcomeSection";
import CircularButton from "../components/CircularButton";
import Toolbar from "../components/Toolbar";

import addIcon from "../images/add.svg";
import analyticsIcon from "../images/analytics.svg";
import background from "../images/FullBackground.svg";
import calendarIcon from "../images/calendar.svg";
import homeIcon from "../images/home.svg";
import tasksIcon from "../images/tasks.svg";


function MainPage() {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isToolbarVisible, setIsToolbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const scrollSensitivity = 20;
    const buttons = [
        { icon: homeIcon },
        { icon: analyticsIcon },
        { icon: calendarIcon },
        { icon: tasksIcon },
    ];

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling down, hide the toolbar
            setIsToolbarVisible(false);
        } else {
            // Scrolling up, show the toolbar
            setIsToolbarVisible(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <StyledMainPage className="App" $open={uploadOpen}>
            <WelcomeSection />
            <OverviewSection navigateTo="/overview" />
            <ReportSection />
            {!uploadOpen && (
                <BottomToolbar $isVisible={isToolbarVisible}>
                    <Toolbar buttons={buttons} />
                    <StyledCircularButton
                        size={"56px"}
                        onSubmit={() => setUploadOpen((prevState) => !prevState)}
                        icon={addIcon}
                    />
                </BottomToolbar>
            )}
            <UploadPage
                open={uploadOpen}
                onChange={() => setUploadOpen((prevState) => !prevState)}
            />
        </StyledMainPage>
    );
}

const StyledMainPage = styled.div<{$open: boolean}>`
    background-image: url(${background});
    min-height: 100vh;
    width: 100vw;
    overflow-y: ${(props) => (props.$open ? "hidden" : "auto")};
    overflow-x: hidden;
`;

const BottomToolbar = styled.div<{$isVisible: boolean}>`
    position: fixed;
    gap: 30px;
    bottom: ${(props) => (props.$isVisible ? "0" : "-100px")}; /* Hide toolbar when not visible */
    left: 0;
    width: 100%;
    background: linear-gradient(
            to top,
            rgba(21, 21, 28, 1) 0%,
            rgba(21, 21, 28, 0.8) 30%,
            rgba(21, 21, 28, 0) 70%
    );
    padding-bottom: 10px;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: bottom 0.3s ease-in-out; 
`;

const StyledCircularButton = styled(CircularButton)`
    margin-right: 10px;
`;

export default MainPage;

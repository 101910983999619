import React from 'react';
import {ValueFunctions} from "./CircularProgressWidget";

function CustomCircularProgress({
                                    actualProgress,
                                    projectedProgress,
                                    maxValue,
                                    size = 120,
                                    strokeWidth = 5,
                                    valueFormat = ValueFunctions.percentage
                                }) {
    const center = size / 2;
    const radius = center - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    const actualOffset =
        circumference - (actualProgress / maxValue) * circumference;
    const projectedOffset =
        circumference - (Math.abs(actualProgress - projectedProgress) / maxValue) * circumference;
    const angle = actualProgress >=  projectedProgress?
        (actualProgress / maxValue) * 360 + 90 - (actualProgress - projectedProgress) / maxValue * 360
        : (actualProgress / maxValue) * 360 + 90 +6;

    const projectedColor = actualProgress >=  projectedProgress? '#2EC056' : '#F95C46'; // Green if ahead, pink if behind

    return (
        <svg width={size} height={size}>
            {/* Background Circle */}
            <circle
                cx={center}
                cy={center}
                r={radius}
                stroke="rgba(235, 236, 240, 0.1)"
                strokeWidth={strokeWidth}
                fill="none"
            />

            {/* Actual Progress Circle */}
            <circle
                cx={center}
                cy={center}
                r={radius}
                stroke="#00B2FF"
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={actualOffset}
                transform={`rotate(90 ${center} ${center})`}
                strokeLinecap="round"
            />
            {/* Projected Progress Circle */}
            <circle
                cx={center}
                cy={center}
                r={radius}
                stroke={projectedColor}
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={projectedOffset}
                transform={`rotate(${angle} ${center} ${center})`}
                strokeLinecap="round"
            />
            {/* Text in the center */}
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                fill="#fff"
                fontSize="20px"
            >
                {valueFormat(actualProgress, maxValue)}
            </text>
        </svg>
    );
}

export default CustomCircularProgress;
import React from "react";
import styled from "styled-components";
import WideButton from "./WideButton";
import { useNavigate } from "react-router-dom";

function ContentSection({ title, children, navigateTo }) {
    const navigate = useNavigate();

    const handleViewAllClick = () => {
        console.log("Attempting to navigate to:", navigateTo); // Debug: Check the navigation target
        navigate(navigateTo);
    };

    return (
        <OverviewContainer>
            <HeaderContainer>
                <HeaderText>{title}</HeaderText>
                <WideButton text={"View All"} onClick={handleViewAllClick} />
            </HeaderContainer>
            <ContentDiv>
                {children}
            </ContentDiv>
        </OverviewContainer>
    );
}

const OverviewContainer = styled.main`
    display: flex;
    flex-direction: column;
    @media (max-width: 640px) {
        margin: 0 auto;
    }
`;

const HeaderContainer = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5% 4% 2% 4%;
`;

const HeaderText = styled.span`
    font: 500 18px/28px Inter, sans-serif;
    color: #fff;
    vertical-align: center;
`;

const ContentDiv = styled.div`
    box-sizing: border-box;
    padding: 2% 0% 2% 4%;
    display: block;
    overflow-y: hidden;
`;

export default ContentSection;
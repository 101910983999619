import React, { useState } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

function BreakdownBarChart({
                               data,
                               Unit = "days",
                               title }) {
    const [unit, setUnit] = useState('percentage'); // Default to percentage

    // Handle unit change (percentage or actual)
    const handleUnitChange = (newUnit) => {
        setUnit(newUnit);
    };

    const ProcessedData = data.map((item) => {
        const ActualPercentage = (item.CurrentAmount / (item.CurrentAmount + item.ActualRemainingAmount)) * 100;
        const PlannedPercentage = (item.CurrentAmount / item.ProjectedMaxValue) * 100;
        const PlannedETC = item.ProjectedMaxValue - item.CurrentAmount;
        const DiffDays = Math.abs(PlannedETC - item.ActualRemainingAmount);
        const ActualValue = item.CurrentAmount;
        const PlannedValue = item.ProjectedMaxValue - item.CurrentAmount;
        const Diff = Math.abs(ActualPercentage - PlannedPercentage);
        let plannedColor = '#F95C46';

//TODO: Fix the unit calc
        if (ActualPercentage > PlannedPercentage) {
            plannedColor = '#2EC056';
            if (unit === 'percentage') {
                return {
                    name: item.name,
                    actual: ActualPercentage - Diff,
                    planned: Diff,
                    plannedColor: plannedColor,
                };
            } else {
                return {
                    name: item.name,
                    actual: ActualValue - DiffDays,
                    planned: DiffDays,
                    plannedColor: plannedColor,
                };
            }
        }
        else {
            if (unit === 'percentage') {
                return {
                    name: item.name,
                    actual: ActualPercentage,
                    planned: Diff,
                    plannedColor: plannedColor,
                };
            } else {
                return {
                    name: item.name,
                    actual: ActualValue,
                    planned: DiffDays,
                    plannedColor: plannedColor,
                };
            }
        }
    });

    return (
        <ChartWrapper>
            <Header>
                <Title>{title}</Title>
            </Header>
            <SubtitleUnitContainer>
                <Subtitle>Breakdown of Planned vs Actual</Subtitle>
                <UnitSelector>
                    <DropdownButton>
                        {unit === 'percentage' ? '%' : Unit}
                        <DropdownContent>
                            <DropdownItem onClick={() => handleUnitChange('percentage')}>%</DropdownItem>
                            <DropdownItem onClick={() => handleUnitChange('actual')}>{Unit}</DropdownItem>
                        </DropdownContent>
                    </DropdownButton>
                </UnitSelector>
            </SubtitleUnitContainer>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart
                    data={ProcessedData}
                    margin={{
                        top: 20,
                        right: 10,
                        left: -19,
                        bottom: 20,
                    }}
                    barCategoryGap="100%"
                >
                    <CartesianGrid strokeDasharray="11 6" stroke="#26232A" />
                    <XAxis dataKey="name" fontSize={10} interval={0} tick={{ fill: '#8A8E9E' }} />
                    <YAxis tick={{ fill: '#8A8E9E' }} domain={unit === 'percentage' ? [0, 100] : ['auto', 'auto']} />
                    <Bar dataKey="actual" fill="#355BE1" stackId="a" radius={[10, 10, 10, 10]} barSize={10} />
                    <Bar dataKey="planned" stackId="a" radius={[10, 10, 10, 10]} barSize={10}>
                        {
                            ProcessedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.plannedColor} />
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
}

// Styled Components

const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
`;

const Title = styled.h3`
    text-align: left;
    margin-top: 8px;
    color: #fff;
    font: 500 19px/19px Inter, sans-serif;
`;

const SubtitleUnitContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    width: 100%;
`;

const Subtitle = styled.h4`
    color: #ccc;
    text-align: left;
    font-size: 0.9em;
    font-weight: normal;
    word-break: break-word;
    max-width: 120px;
    margin-right: 10px;
`;

const UnitSelector = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 30px;
`;

const DropdownButton = styled.button`
    background-color: RGBA(235, 236, 240, 0.1);
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 50px;
`;
//TODO: make a dropdown component
const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: RGBA(235, 236, 240, 0.1);
    border-radius: 10px;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0; 
    margin-top: 10px;
    ${UnitSelector}:hover & {
        display: block;
    }
`;

const DropdownItem = styled.a`
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    &:hover {
        background-color: #575757;
    }
`;

export default BreakdownBarChart;
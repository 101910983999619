import React, {Fragment, useEffect, useRef, useState} from "react";
import styled, { createGlobalStyle } from "styled-components";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import ChatImage from "./OBXImage";
import {BlinkBlur, FourSquare, Mosaic, ThreeDot} from "react-loading-indicators";
interface ChatTextProps {
    text?: string;
    images?: string[];
    leftAligned?: boolean;
    generator?: (a: (b: any) => void) => void;
}

interface ChatStyleProps {
    $leftAligned?: boolean;
    $height?: number;
}
function ChatText({ text = "", images = [], leftAligned = true, generator = null } : ChatTextProps) {
    const textRef = useRef(null);
    const imageRef = useRef(null);
    const [textState, setText] = useState(text);
    const [imageState, setImages] = useState(images);
    const [textHeight, setTextHeight] = useState(50);
    useEffect(() => {
        if (generator) {
            const onGenerated = (content: { [x: string]: any; }) => {
                if (content["messages"]) {
                    setText(prevState => {
                        let result = prevState;
                        for (let message of content["messages"]) {
                            result += message;
                        }
                        return result;
                    })
                }
                if (content["image_urls"]) {
                    setImages(prevState => [...prevState, ...content["image_urls"]]);
                }
            }
            generator(onGenerated)
        }
    }, []);
    useEffect(() => {
        console.log("Text state changed");
        setTextHeight(textRef.current.clientHeight + imageRef.current?.clientHeight);

    }, [textState]);

    return (
        <BubbleContainer $leftAligned={leftAligned} $height={textHeight}>
            <Bubble $leftAligned={leftAligned} $height={textHeight}>
                <Fragment>
                    <GlobalStyle />
                    {imageState.length > 0 && (
                        <ImagesContainer ref={imageRef}>
                            {imageState.map((src, index) => (
                                <ChatImage key={index} src={src} alt={`image-${index}`}  style={{maxWidth: "100%"}}
                                />
                            ))}
                        </ImagesContainer>
                    )}
                    <TextContainer ref={textRef}>
                        <Markdown remarkPlugins={[gfm]}>{textState}</Markdown>
                    </TextContainer>
                    {!textState && <LoadingContainer>
                            <ThreeDot variant="bob"
                                      color="#6b4d8a"
                                      style={{ fontSize: "8px" }}
                                      speedPlus={-2}
                                      text=""
                                      textColor="" />
                        </LoadingContainer>}
                </Fragment>
            </Bubble>
        </BubbleContainer>
    );
}


const BubbleContainer = styled.div<ChatStyleProps>`
    display: flex;
    flex-direction: row;
    font: 400 16px/20px Inter, sans-serif;
    width: 100%;
    color: #fff;
    min-height: 50px;
    height: ${props => `${props.$height + 8}px`} !important;
    justify-content: ${props => props.$leftAligned ? "left" : "right"};
    box-sizing: border-box;
    margin-bottom: 15px;
    padding-bottom: 5px;
`;

const Bubble = styled.div<ChatStyleProps>`
    background-color: ${props => props.$leftAligned ? "rgba(255, 255, 255, 0.04)" : "rgba(255, 255, 255, 0.15)"};
    border-radius: 19px;
    width: 70%;
    padding: 0.5% 4%;
    word-wrap: break-word;
    white-space: pre-wrap;
    height: ${props => `${props.$height}px`};
`;

const TextContainer = styled.div`
    display: inline-block;
    word-wrap: break-word;
    white-space: pre-wrap;
    `;

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
`;

const LoadingContainer = styled.div`
    display: block;
    margin-top: 8px;
`;

const GlobalStyle = createGlobalStyle`
    table {
        margin: 0 15% !important;
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        border-color: inherit !important;
        display: block !important;
        width: 70% !important;
        max-width: 100% !important;
        overflow: auto !important;
        align-content: center !important;
    }
    td, th {
        border-color: inherit !important;
        border-style: solid !important;
        border-width: 2px !important;
        padding: 6px 13px !important;
    }
    tbody, tfoot, thead, tr {
        margin: 0 auto !important;
        border-color: inherit !important;
        border-style: solid !important;
        border-width: 2px !important;
        width: 100% !important;
    }
`;



export default ChatText;

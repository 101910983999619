import React, { useState } from "react";
import styled from "styled-components";
import indicatorIcon from '../images/Indicator.svg';

export type ToolbarButton = {
    icon?: string;
    text?: string;
    onClick?: () => void;
};

function Toolbar({ buttons, className } : { buttons: ToolbarButton[], className?: string }) {
    const [selectedButton, setSelectedButton] = useState(0); // Default to the first button
    const GetButton = (button: ToolbarButton, index: number) => {
        if (!button.icon != !button.text) {
            return (
                <ToolbarButton
                    key={index}
                    selected={selectedButton === index}
                    onClick={() => {
                        setSelectedButton(index)
                        button.onClick && button.onClick();
                    }}
                >
                    {button.icon ? <Icon src={button.icon} alt={`icon-${index}`}/> : button.text}
                    {selectedButton === index && <IndicatorIcon src={indicatorIcon} alt="indicator" />}
                </ToolbarButton>
            )
        }
    }
    return (
        <ToolbarContainer className={className}>
            {buttons.map(GetButton)}
        </ToolbarContainer>
    );
}

const ToolbarContainer = styled.div`
    background-color: #1640D6;
    padding: 6px 10px;
    border-radius: 50px;
    display: flex;
    gap: 20px;
    z-index: 1000;
`;

const ToolbarButton = styled.button<{selected: boolean}>`
    background-color: transparent;
    border: none;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    opacity: ${(props) => (props.selected ? "1" : "0.5")};
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    /*filter:{(props) => (props.selected ? "none" : "brightness(0) invert(1)")};*/
`;

const IndicatorIcon = styled.img`
    position: absolute;
    bottom: -10.9px;
    left: 50%;
    transform: translateX(-50%);
    width: 16px; 
    height: 16px;
`;

export default Toolbar;

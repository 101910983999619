import React from "react";
import styled from "styled-components";

function CircularButton({
                        icon,
                        className = "button",
                        size = "48px",
                        color = "#1640D6",
                        onSubmit = () => {}
                    }) {
    return (
        <div className={className}>
            <Button
                type={"submit"}
                onSubmit={onSubmit}
                onClick={onSubmit}
                $color={color}
                $size={size}
            >
                {icon && <Icon src={icon} alt="icon" />}
            </Button>
        </div>
    );
}

const Button = styled.button`
    border: none;
    padding: 0;
    background-color: ${props => props.$color};
    border-radius: 50%;
    width: ${props => props.$size}; 
    height: ${props => props.$size};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: darken(${props => props.$color}, 10%);
    }
`;

const Icon = styled.img`
    width: 50%;
    height: 50%;
    object-fit: contain;
`;

export default CircularButton;

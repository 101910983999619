import axios from "axios";

const process_url = "https://api.oblix.tech";
const chat_url = "https://api.oblix.tech";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

async function submitMessage(message: string, sessionId: string = "") {
  return axios.post(chat_url + "/chat", { message: message, session_id: sessionId});
}

async function streamMessage(message: string, handleEvent: (a: any) => void, sessionId: string = ""): Promise<void>{
  axios.post(chat_url + "/chat", { message: message, session_id: sessionId},
      {
          headers: {
              'Accept': 'text/event-stream',
          },
          responseType: 'stream',
          adapter: 'fetch'
      }).then(async (response) => {
          console.log('axios got a response');
          const stream = response.data;

          // consume response
          const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
          while (true) {
              const { value, done } = await reader.read();
              if (done) break;
              value.split("}").forEach(
                    (message: string) => {
                        if (message.length > 0) {
                            // console.log(message);
                            let json : any = JSON.parse(message + "}");
                            handleEvent(json);
                        }
                    }
              )
              //console.log(JSON.parse(value));
          }
  });
}

async function submitVideo(file: string | Blob) {
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(process_url + "/input/image", formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
      console.log(response);
    }).catch(error => {
      console.log(error);
    });
}
type ZoneProps = {
    id: string,
    description: string,
    files: File[]
};

type ZoneFormProps = {
    comment?: string;
    date: string;
    zones: ZoneProps[];
}
async function uploadZoneForm(formData: ZoneFormProps): Promise<number> {
    let form = new FormData();
    form.append("comment", formData.comment);
    form.append("date", formData.date);
    form.append("project_id", "test_project")

    formData.zones.forEach((zone, index) => {
        form.append(`zones[${index}][zone_id]`, zone.id);
        form.append(`zones[${index}][description]`, zone.description);
        zone.files.forEach((file: string | Blob, fileIndex: any) => {
            form.append(`zones[${index}][files][${fileIndex}]`, file);
        });
    });
    let statusCode = 404;
    try {
        const response = await axios.post(process_url + '/image/zone', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        statusCode = response.status;
        console.log(response.data);
    } catch (error) {
        console.error(error);
    }
    return statusCode;
}
type ListImagesResult = {
    zoneName: string;
    description: string;
    images: {
        id: string;
        image_url: string;
    }[];
}


async function list_images() : Promise<ListImagesResult[]> {
    const project_id = "test_project";
    const response = await axios.get(process_url + `/image/${project_id}/list`);
    let results = [];
    for (const image of response.data['results']) {
        console.log(image);
        const result = {
            "zoneName": image["_id"],
            "description": image['description'],
            "images": image['images'].map((img_id: any) => {
                return {
                    "id": img_id,
                    "image_url": `${process_url}/image/${project_id}/${img_id}`
                }
            })
        }
        results.push(result);
    }
    return results;
}

async function remove_image(image_id: string, project_id: string = "test_project") {
    return axios.delete(process_url + `/image/${project_id}/${image_id}`);
}
async function ping() {
  let response = await axios.get("/ping");
  return response.data;
}

export {
    submitMessage,
    streamMessage,
    ping,
    submitVideo,
    uploadZoneForm,
    list_images,
    remove_image,
    ZoneProps,
    ZoneFormProps,
    ListImagesResult
};